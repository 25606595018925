<template>
  <div>
    <div class="card p-5 mx-auto">
      <h2 class="text-center">Stundenzettel erstellen</h2>
      <select class="w-25 mx-auto browser-default custom-select mt-3" v-model="selectedWorker">
        <option value="1" disabled selected>Mitarbeiter auswählen</option>
        <option v-for="(worker, key) in workers" :key="key">
          {{ worker.firstName }}
        </option>
      </select>
      <select class="w-25 mx-auto browser-default custom-select mt-3" v-model="selectedMonth">
        <option value="1" disabled selected>Monat auswählen</option>
        <option v-for="(month, key) in months" :key="key">
          {{ month.full }}
        </option>
      </select>
      <select class="w-25 mx-auto browser-default custom-select mt-3" v-model="selectedYear">
        <option value="1" disabled selected>Jahr auswählen</option>
        <option v-for="(year, key) in yearList" :key="key">
          {{ year }}
        </option>
      </select>
      <button class="w-25 mx-auto mt-5 btn btn-primary" @click="creationStart()">
        Herunterladen
      </button>
    </div>
  </div>
</template>

<script>
import { jsPDF } from 'jspdf';
import { mapGetters } from 'vuex';

export default {
  components: {},
  data() {
    return {
      selectedWorker: 1,
      selectedMonth: 1,
      selectedYear: 1,
      months: [
        { full: 'Januar', short: 'jan' },
        { full: 'Februar', short: 'feb' },
        { full: 'März', short: 'mar' },
        { full: 'April', short: 'apr' },
        { full: 'Mai', short: 'may' },
        { full: 'Juni', short: 'jun' },
        { full: 'Juli', short: 'jul' },
        { full: 'August', short: 'aug' },
        { full: 'September', short: 'sep' },
        { full: 'Oktober', short: 'oct' },
        { full: 'November', short: 'nov' },
        { full: 'Dezember', short: 'dec' },
      ],
      workers: [
        { firstName: 'Torsten', lastName: 'Stoltze', role: 'full' },
        { firstName: 'WolfgangZettel', lastName: 'Starke', role: 'half-time' },
        { firstName: 'Wolfgang', lastName: 'Starke', role: 'half-time' },
        { firstName: 'Marek', lastName: 'Lamprecht', role: 'mini-job' },
        { firstName: 'Kira', lastName: 'Lamprecht', role: 'mini-job' },
        { firstName: 'Andrea', lastName: 'Lamprecht', role: 'mini-job' },
      ],
      timesheetsData: {
        headline: '',
      },
      currentYear: null,
      yearList: [],
    };
  },
  mounted() {
    this.currentYear = new Date().getFullYear();
    this.yearList.push(this.currentYear);
    this.yearList.push(2024);
    this.yearList.push(2023);
    this.yearList.push(2022);
    this.yearList.push(2021);
  },
  computed: {
    ...mapGetters([
      'allProjectEntries',
      'allWorkingTime',
      'projectEntriesForPaper',
      'workingTimeForPaper',
    ]),
  },
  methods: {
    creationStart() {
      const weekNumbersInMonth = this.getWeeksInMonth(
        this.getDaysInMonth(
          this.months.find((month) => month.full === this.selectedMonth).short,
          this.currentYear,
        ),
      );

      const data = {
        worker: this.selectedWorker,
        lowestWeek: Math.min(...weekNumbersInMonth),
        highestWeek: Math.max(...weekNumbersInMonth),
        year: this.selectedYear,
      };

      this.$store.dispatch('GET_DAY_ENTRIES_BY_WEEKS', data).then(() => {
        const selectedWorkerMap =
          this.selectedWorker === 'WolfgangZettel' ? 'Wolfgang' : this.selectedWorker;
        this.timesheetsData.headline =
          this.workers.find((worker) => worker.firstName === selectedWorkerMap).firstName +
          ' ' +
          this.workers.find((worker) => worker.firstName === selectedWorkerMap).lastName +
          ' ' +
          this.selectedMonth +
          ' ' +
          this.selectedYear;
        this.$store.dispatch('GET_WORKING_TIME_BY_WEEKS', data).then(() => {
          this.createPDF();
        });
      });
    },
    createPDF() {
      const headers = this.createHeaders();
      const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'portrait' });
      doc.setFontSize(20);
      doc.text('Stundenzettel ' + this.timesheetsData.headline, 20, 10);
      doc.setFontSize(6);
      doc.table(
        20,
        20,
        this.generateData(
          this.getDaysInMonth(
            this.months.find((month) => month.full === this.selectedMonth).short,
            this.selectedYear,
          ),
        ),
        headers,
        { fontSize: 8, padding: 2 },
      );
      doc.save(
        `Stundenzettel-${
          this.selectedWorker === 'WolfgangZettel' ? 'Wolfgang' : this.selectedWorker
        }-${this.selectedMonth}.pdf`,
      );
    },
    generateData(monthLength) {
      const result = [];
      const weekDays = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
      const weekDaysFull = [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
      ];

      for (var i = 1; i <= monthLength; i += 1) {
        const elementDate = new Date(
          this.selectedYear,
          new Date(
            Date.parse(
              this.months.find((month) => month.full === this.selectedMonth).short +
                ' 1,' +
                this.selectedYear,
            ),
          ).getMonth(),
          i,
        );
        const elementWeekNumber = this.getWeekNumber(elementDate);
        const elementWeekDay = weekDaysFull[elementDate.getDay()];
        result.push(
          Object.assign(
            {},
            {
              Datum: i + '. ' + weekDays[elementDate.getDay()],
              Baustellen: this.getConstructionProjects(elementWeekNumber, elementWeekDay).join(
                '; ',
              ),
              Beginn: this.getStartTime(elementWeekNumber, elementWeekDay),
              Pause: this.getBreak(elementWeekNumber, elementWeekDay),
              Ende: this.getEndTime(elementWeekNumber, elementWeekDay),
              Std: this.getWorkedHours(elementWeekNumber, elementWeekDay),
              ZK: this.getHoursFromTime(elementWeekNumber, elementWeekDay),
              Soll: this.getSollHours(weekDays[elementDate.getDay()]),
              id: i + 1,
            },
          ),
        );
      }
      return result;
    },
    createHeaders() {
      var result = [
        {
          id: 'Datum',
          name: 'Datum',
          prompt: 'Datum',
          align: 'left',
          fontSize: '6',
          width: '20',
          padding: 0,
        },
        {
          id: 'Baustellen',
          name: 'Baustellen',
          prompt: 'Baustellen',
          align: 'center',
          fontSize: '6',
          width: '115',
          padding: 0,
        },
        {
          id: 'Beginn',
          name: 'Beginn',
          prompt: 'Beginn',
          align: 'center',
          width: '20',
          fontSize: '6',
          padding: 0,
        },
        {
          id: 'Pause',
          name: 'Pause',
          prompt: 'Pause',
          align: 'center',
          fontSize: '6',
          width: '20',
          padding: 0,
        },
        {
          id: 'Ende',
          name: 'Ende',
          prompt: 'Ende',
          align: 'center',
          fontSize: '6',
          width: '20',
          padding: 0,
        },
        {
          id: 'Std',
          name: 'Std',
          prompt: 'Std',
          align: 'center',
          fontSize: '6',
          width: '20',
          padding: 0,
        },
        {
          id: 'ZK',
          name: 'ZK',
          prompt: 'ZK',
          align: 'center',
          fontSize: '6',
          width: '15',
          padding: 0,
        },
        {
          id: 'Soll',
          name: 'Soll',
          prompt: 'Soll',
          align: 'center',
          fontSize: '6',
          width: '15',
          padding: 0,
        },
      ];
      return result;
    },
    getConstructionProjects(weekNumber, weekDay) {
      let returnArray = [];
      this.projectEntriesForPaper.forEach((element) => {
        if (element.week === weekNumber && element.day === weekDay) {
          const string = `${element.project}${
            element.project === 'Urlaub' ? '' : ': ' + element.hours
          }`;
          returnArray.push(string);
        }
      });
      if (returnArray.length === 0) returnArray = [' '];
      return returnArray;
    },
    getHoursFromTime(weekNumber, weekDay) {
      let returnArray = [];
      this.projectEntriesForPaper.forEach((element) => {
        if (
          element.week === weekNumber &&
          element.day === weekDay &&
          element.project === 'Zeitkonto'
        ) {
          return element.hours;
        } else {
          return ' ';
        }
      });
      if (returnArray.length === 0) returnArray = [' '];
      return returnArray;
    },
    getBreak(weekNumber, weekDay) {
      const filteredArray = this.workingTimeForPaper.filter(
        (element) => element.week === weekNumber && element.day === weekDay,
      );
      if (filteredArray.length > 0) {
        return filteredArray[0].break ? '0,5' : '0';
      } else {
        return ' ';
      }
    },
    getStartTime(weekNumber, weekDay) {
      const filteredArray = this.workingTimeForPaper.filter(
        (element) => element.week === weekNumber && element.day === weekDay,
      );
      if (filteredArray.length > 0) {
        return filteredArray[0].start_time;
      } else {
        return ' ';
      }
    },
    getEndTime(weekNumber, weekDay) {
      const filteredArray = this.workingTimeForPaper.filter(
        (element) => element.week === weekNumber && element.day === weekDay,
      );
      if (filteredArray.length > 0) {
        return filteredArray[0].end_time;
      } else {
        return ' ';
      }
    },
    getWorkedHours(weekNumber, weekDay) {
      const filteredArray = this.workingTimeForPaper.filter(
        (element) => element.week === weekNumber && element.day === weekDay,
      );
      if (filteredArray.length > 0) {
        return filteredArray[0].total;
      } else {
        return ' ';
      }
    },
    getDaysInMonth(month, year) {
      const monthNum = new Date(Date.parse(month + ' 1,' + year)).getMonth() + 1;
      return new Date(year, monthNum, 0).getDate();
    },
    getSollHours(day) {
      const elementDate = new Date(
        Date.parse(
          this.months.find((month) => month.full === this.selectedMonth).short +
            ' 1,' +
            this.currentYear,
        ),
      ).getMonth();
      const sommerHours = [3, 4, 5, 6, 7, 8, 9, 10];
      const winterHours = [0, 1, 2, 11];

      let hoursMoDiMiDo = '';
      let hoursFr = '';

      if (winterHours.includes(elementDate)) {
        hoursMoDiMiDo = '8';
        hoursFr = '6';
      } else if (sommerHours.includes(elementDate)) {
        hoursMoDiMiDo = '8,5';
        hoursFr = '7';
      }

      // ? Winter
      // const winterHoursMoDiMiDo = '8';
      // const winterHoursFr = '6';
      // ? Sommer
      // const normalHoursMoDiMiDo = '8,5';
      // const normalHoursFr = '7';

      if (this.selectedWorker === 'Wolfgang' || this.selectedWorker === 'WolfgangZettel') {
        if (day === 'Sa') {
          return ' ';
        } else if (day === 'So') {
          return ' ';
        } else if (day === 'Fr') {
          return ' ';
        } else if (day === 'Mo') {
          return ' ';
        } else {
          return hoursMoDiMiDo;
        }
      }
      if (this.selectedWorker === 'Torsten') {
        if (day === 'Sa') {
          return ' ';
        } else if (day === 'So') {
          return ' ';
        } else if (day === 'Fr') {
          return hoursFr;
        } else {
          return hoursMoDiMiDo;
        }
      }

      if (
        this.selectedWorker === 'Marek' ||
        this.selectedWorker === 'Kira' ||
        this.selectedWorker === 'Andrea'
      ) {
        return ' ';
      }
    },
    getWeekNumber(d) {
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      return weekNo;
    },
    getWeeksInMonth(monthLength) {
      const arrayOfWeeks = [];
      for (let i = 1; i <= monthLength; i += 1) {
        const elementDate = new Date(
          this.currentYear,
          new Date(
            Date.parse(
              this.months.find((month) => month.full === this.selectedMonth).short +
                ' 1,' +
                this.currentYear,
            ),
          ).getMonth(),
          i,
        );
        arrayOfWeeks.push(this.getWeekNumber(elementDate));
      }
      return [...new Set(arrayOfWeeks)];
    },
  },
};
</script>
